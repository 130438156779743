import { Box, TextField, Select, MenuItem, FormControl, InputLabel } from "@mui/material";
import { Formik } from "formik";
import * as yup from "yup";
import useMediaQuery from "@mui/material/useMediaQuery";
import Header from "../../../components/Header";
import { mockPaises } from '../../../data/mockPaises copy'
import SnackBars from "../../../components/CustomizedSnackBars";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import {useFetch} from '../../../hooks/useFetch';
import LoginProfile from "../../../hooks/useLoginUser";
import '../../../utils/i18n';
import { useTranslation } from "react-i18next";

const FormInvite = () => {
  const { t, i18n } = useTranslation();
  const isNonMobile = useMediaQuery("(min-width:600px)");
  const [method,setMethod] = useState(null);
  const [data, setData] = useState([]);
  const [team, setTeam] = useState([]);

  //recebe id do account para carregar o form para edição
  
  const { id } = useParams();
  const api = process.env.REACT_APP_API_URL;
  //----------------------------------------------------

  //seta url para cadastro (POST) e grava o retorno da API no Resp
  const [resp, setResp] = useState(null);
  //-------------------------------------------------------------

  //seta o resp como null sempre que o form for alterado
  const handleFormChange = () => {
    setResp(null);
  };
  //--------------------------------------------------

  //Handle de submit do form
  const handleFormSubmit = async (values) => {
    const accountUrl = `${api}/login/invite`;
    
    const invite = {
      account_uuid : LoginProfile.getAccountUUID(),
      email : values.email,
    };
    
    const reqAccount = await fetch(accountUrl,{
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "http://localhost:3000",
        "Access-Control-Allow-Methods": "POST, GET, PUT, DELETE",
        "Access-Control-Allow-Headers": "*",
        "Access-Control-Max-Age": "86400",
      },
      body: JSON.stringify(invite),
    });
    setResp(reqAccount.status);   
  };
  //------------------------------------------------

  return (
    <Box m="20px">
      <Header title={t("INVITE ADMINISTRATIVE USERS")} subtitle={t("Invite new administrative users")} />

      <Formik
        onSubmit={handleFormSubmit}
        initialValues={initialValues}
        validationSchema={checkoutSchema}
      >
        {({
          values,
          errors,
          touched,
          handleBlur,
          handleChange,
          handleSubmit,
        }) => (
          <form onSubmit={handleSubmit} onChange={handleFormChange}>
            <Box
              display="grid"
              gap="30px"
              gridTemplateColumns="repeat(4, minmax(0, 1fr))"
              sx={{
                "& > div": { gridColumn: isNonMobile ? undefined : "span 4" },
              }}
            >
              <TextField
                fullWidth
                variant="filled"
                type="text"
                label="Email"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.email}
                name="email"
                error={!!touched.email && !!errors.email}
                helperText={touched.email && errors.email}
                sx={{ gridColumn: "span 4" }}
              />
            </Box>
            <SnackBars buttonName={t("SEND INVITE")} buttonType="submit" resp={resp} />
          </form>
        )}
      </Formik>
    </Box>
  );
};

const phoneRegExp =
  /^((\+[1-9]{1,4}[ -]?)|(\([0-9]{2,3}\)[ -]?)|([0-9]{2,4})[ -]?)*?[0-9]{3,4}[ -]?[0-9]{3,4}$/;

const checkoutSchema = yup.object().shape({
  email: yup.string().email("invalid email").required("required")
});
const initialValues = {
  email: ""
};

export default FormInvite;
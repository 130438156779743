import { Box, Button, IconButton, Typography, useTheme } from "@mui/material";
import { tokens } from "../../theme";
import DownloadOutlinedIcon from "@mui/icons-material/DownloadOutlined";
import Header from "../../components/Header";
import LineChart from "../../components/LineChart";
import BarChart from "../../components/BarChart";
import { useLocation } from "react-router-dom";
import { useEffect, useState } from "react";
import TeamProfile from "../../hooks/useTeam";
import '../../utils/i18n';
import { useTranslation } from "react-i18next";

const mocklineChart = [
  {
    "id": 0,
    "data": [
        {
            "x": "",
            "y": 0
        },
    ]
  }
];

const mockKeyLineChart = ['People'];

const SafetyCheckDashboard = () => {
  const { t, i18n } = useTranslation();
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [lineChart, setLineChart] = useState([]);
  const [keysLineChart, setKeysLineChart] = useState([]);
  const [percentClassification, setPercentClassification] = useState([]);
  const [keysPercentClassification, setKeysPercentClassification] = useState([]);
  const [total, setTotal] = useState([]);

  const api = process.env.REACT_APP_API_URL;

  //const {TeamProfile.getAccountID(),campaign_id} = useParams();

  const location = useLocation();


  const fetchHappinessEnergyMotivation = async () => {
    const urlTimeLine = `${api}/safetycheck/safetyCheckAvgLineChart/team/${TeamProfile.getTeamID()}`;
    const options = {
      method: 'GET',
      headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "http://localhost:3000",
          "Access-Control-Allow-Methods": "POST, GET, PUT, DELETE",
          "Access-Control-Allow-Headers": "*",
          "Access-Control-Max-Age": "86400",
          "Authorization": `${sessionStorage.getItem('token')}`
      }
    };
    const respTimeLine = await fetch(urlTimeLine, options);
    const jsonTimeLine = await respTimeLine.json(respTimeLine);
    setLineChart(jsonTimeLine);
    setKeysLineChart(handleKeys(jsonTimeLine));
  };

  useEffect(() => {
    const fetchData = async () => {
      const fetchWithHeaders = async (url) => {
        const options = {
          method: 'GET',
          headers: {
              "Content-Type": "application/json",
              "Access-Control-Allow-Origin": "http://localhost:3000",
              "Access-Control-Allow-Methods": "POST, GET, PUT, DELETE",
              "Access-Control-Allow-Headers": "*",
              "Access-Control-Max-Age": "86400",
              "Authorization": `${sessionStorage.getItem('token')}`
          }
        };

        const resp = await fetch(url, options);
        return resp.json();
      }

      try {
        const [percentClassificationResponse, totalResponse] = await Promise.all([
          fetchWithHeaders(`${api}/dashboard/safetyCheckClassification/team/${TeamProfile.getTeamID()}`),
          fetchWithHeaders(`${api}/safetycheck/avgbignumbersafetycheck/team/${TeamProfile.getTeamID()}`)
        ]);
    
        setTotal(totalResponse);
        setPercentClassification(percentClassificationResponse);
        setKeysPercentClassification(handleKeys(percentClassificationResponse));
      }
      catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchHappinessEnergyMotivation();
    fetchData();
  }, [TeamProfile.getTeamID(), location.pathname]);

  const handleKeys = (peopleTarget) => {
    const key = new Array();
    if(peopleTarget){
      peopleTarget.map((itens) =>{
        if(!key.includes(itens.item)){
          key.push(itens.item);
        }
        return itens.item;
      });
    }
    return key;
  };

  let total_score;

  if(total){
    total.map((itens) => {
      total_score = itens.score;
      return total_score;
    });
  }

  const handleIncrease = (score) => {
    let result = (parseFloat(score) / 5) * 100 ;
    return result
  };

  const handleToFixedIncrease = (score) => {
    return score.toFixed(2);
  };


  return (
    <Box m="20px">
      {/* HEADER */}
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Header title={t("SAFETY CHECK DASHBOARD")} subtitle={t("Welcome to safety check dashboard")} />
        <Box>
          <Button
            sx={{
              backgroundColor: colors.blueAccent[700],
              color: colors.grey[100],
              fontSize: "14px",
              fontWeight: "bold",
              padding: "10px 20px",
            }}
          >
            <DownloadOutlinedIcon sx={{ mr: "10px" }} />
            Download Reports
          </Button>
        </Box>
      </Box>

      {/* GRID & CHARTS */}
      <Box
        display="grid"
        gridTemplateColumns="repeat(12, 1fr)"
        gridAutoRows="140px"
        gap="20px"
      >

        {/* ROW 1 */}
        <Box
          gridColumn="span 12"
          gridRow="span 2"
          backgroundColor={colors.primary[400]}
          borderRadius="10px"
        >
          <Box
            mt="25px"
            p="0 30px"
            display="flex "
            justifyContent="space-between"
            alignItems="center"
          >
            <Box>
              <Typography
                variant="h5"
                fontWeight="600"
                color={colors.grey[100]}
              >
                {t("Satisfaction Time Line")}
              </Typography>
              <Typography
                variant="h3"
                fontWeight="bold"
                color={colors.greenAccent[500]}
              >
                {total_score && handleToFixedIncrease(total_score)}
              </Typography>
            </Box>
            <Box>
              <IconButton>
                <DownloadOutlinedIcon
                  sx={{ fontSize: "26px", color: colors.greenAccent[500] }}
                />
              </IconButton>
            </Box>
          </Box>
          <Box height="85%" m="-20px">
            <LineChart isDashboard={false} data={lineChart ? lineChart : mocklineChart} keys={keysLineChart ? keysLineChart : mockKeyLineChart} maxValue={5}  />
          </Box>
        </Box>
      {/* ROW 1 */}

      {/* ROW 2 */}
      <Box
          gridColumn="span 12"
          gridRow="span 2"
          backgroundColor={colors.primary[400]}
          borderRadius="10px"
        >
          <Typography
            variant="h5"
            fontWeight="600"
            sx={{ padding: "30px 30px 0 30px" }}
          >
            {t("Safety level in Percent (%)")}
          </Typography>
          <Box height="85%" mt="-20px">
            <BarChart
              isDashboard={true}
              data={percentClassification ? percentClassification : null}
              keys={keysPercentClassification ? keysPercentClassification : null}
              indexBy="item"
              groupMode="stacked" />
          </Box>
        </Box>
        {/* ROW 2 */}
      </Box>
    </Box>
  );
};

export default SafetyCheckDashboard;

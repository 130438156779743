import { useState } from "react";
import { ProSidebar, Menu, MenuItem } from "react-pro-sidebar";
import { Box, IconButton, Typography, useTheme, Tooltip} from "@mui/material";
import { Link } from "react-router-dom";
import "react-pro-sidebar/dist/css/styles.css";
import { tokens } from "../../theme";
import PeopleOutlinedIcon from "@mui/icons-material/PeopleOutlined";
import MenuOutlinedIcon from "@mui/icons-material/MenuOutlined";
import AutoModeOutlinedIcon from '@mui/icons-material/AutoModeOutlined';
import ManageAccountsOutlinedIcon from '@mui/icons-material/ManageAccountsOutlined';
import SafetyCheckOutlinedIcon from '@mui/icons-material/SafetyCheckOutlined';
import ElectricBoltOutlinedIcon from '@mui/icons-material/ElectricBoltOutlined';
import FavoriteBorderOutlinedIcon from '@mui/icons-material/FavoriteBorderOutlined';
import AutoGraphOutlinedIcon from '@mui/icons-material/AutoGraphOutlined';
import LocalFireDepartmentOutlinedIcon from '@mui/icons-material/LocalFireDepartmentOutlined';
import TouchAppOutlinedIcon from '@mui/icons-material/TouchAppOutlined';
import LoginProfile from "../../hooks/useLoginUser";
import AccountBalanceOutlinedIcon from '@mui/icons-material/AccountBalanceOutlined';
import SchoolOutlinedIcon from '@mui/icons-material/SchoolOutlined';
import '../../utils/i18n';
import { useTranslation } from "react-i18next";
import RecordVoiceOverOutlinedIcon from '@mui/icons-material/RecordVoiceOverOutlined';
import ModelTrainingOutlinedIcon from '@mui/icons-material/ModelTrainingOutlined';


const Item = ({ title, to, icon, selected, setSelected, tooltip }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  return (
    <MenuItem
      active={selected === title}
      style={{
        color: colors.grey[100],
      }}
      onClick={() => setSelected(title)}
      icon={icon}
    >
      <Tooltip title={tooltip} placement="rigth">
        <Typography>{title}</Typography>
      </Tooltip>
      <Link to={to} />
    </MenuItem>
  );
};

const Sidebar = () => {
  const { t, i18n } = useTranslation();
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [isCollapsed, setIsCollapsed] = useState(false);
  const [selected, setSelected] = useState("Dashboard");

  return (
    <Box
      sx={{
        "& .pro-sidebar-inner": {
          background: `${colors.primary[400]} !important`,
        },
        "& .pro-icon-wrapper": {
          backgroundColor: "transparent !important",
        },
        "& .pro-inner-item": {
          padding: "5px 35px 5px 20px !important",
        },
        "& .pro-inner-item:hover": {
          color: "#868dfb !important",
        },
        "& .pro-menu-item.active": {
          color: "#6870fa !important",
        },
      }}
    >
      <ProSidebar collapsed={isCollapsed}>
        <Menu iconShape="square">
          {/* LOGO AND MENU ICON */}
          <MenuItem
            onClick={() => setIsCollapsed(!isCollapsed)}
            icon={isCollapsed ? <MenuOutlinedIcon /> : undefined}
            style={{
              margin: "10px 0 20px 0",
              color: colors.grey[100],

            }}
          >
            {!isCollapsed && (
              <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                ml="15px"
              >
                <Typography variant="h3" color={colors.grey[100]}>
                  {/* TITULO DO SITE */}
                </Typography>
                <IconButton onClick={() => setIsCollapsed(!isCollapsed)}>
                  <MenuOutlinedIcon />
                </IconButton>
              </Box>
            )}
          </MenuItem>

          {!isCollapsed && (
            <Box mb="25px">
              <Box display="flex" justifyContent="center" alignItems="center">
                <img
                  alt="profile-user"
                  width="100px"
                  height="100px"
                  src={`/assets/logo_All_Metrics-removebg-preview-v4.png`}
                  style={{ cursor: "pointer", borderRadius: "50%" }}
                />
              </Box>
              <Box textAlign="center">
                <Typography
                  variant="h2"
                  color={colors.grey[100]}
                  fontWeight="bold"
                  sx={{ m: "10px 0 0 0" }}
                >
                  All Metrics
                </Typography>
                <Typography variant="h5" color={colors.greenAccent[500]}>
                  Good Decisions
                </Typography>
              </Box>
            </Box>
          )}

          <Box paddingLeft={isCollapsed ? undefined : "10%"}>
          <Item
              title={t("Onboarding")}
              to={`/onboarding`}
              icon={<SchoolOutlinedIcon />}
              selected={selected}
              setSelected={setSelected}
            />
            {/*---People Analytics ----------------------------------------------------- */}
            <Typography
              variant="h6"
              color={colors.grey[300]}
              sx={{ m: "15px 0 5px 20px" }}
              tooltip="Satisfaction"
            >
              {isCollapsed ? undefined : "People Analytics"}
            </Typography>
            <Item
              title="Dashboard"
              to="/dashboard/satisfaction"
              icon={<AutoGraphOutlinedIcon />}
              selected={selected}
              setSelected={setSelected}   
              tooltip="Tooltip for Situation Wall"       
            />
            <Item
              title={t("Heatmap")}
              to="/dashboard/heatmap"
              icon={<LocalFireDepartmentOutlinedIcon />}
              selected={selected}
              setSelected={setSelected}
            />
            <Item
              title={t("Happiness Radar")}
              to="/dashboard/happinessradar"
              icon={<FavoriteBorderOutlinedIcon />}
              selected={selected}
              setSelected={setSelected}
            />
            <Item
              title={t("Safety Check")}
              to="/dashboard/safetycheck"
              icon={<SafetyCheckOutlinedIcon />}
              selected={selected}
              setSelected={setSelected}
            />
            <Item
              title={t("Energy and Motivation")}
              to="/dashboard/energymotivation/"
              icon={<ElectricBoltOutlinedIcon />}
              selected={selected}
              setSelected={setSelected}
            />
            {/* 
            <Item
              title="Research"
              to={`/formSafetyCheck/${TeamProfile.getAccountID()}/${localStorage.getItem('campaign_id')}`}
              icon={<QueryStatsOutlinedIcon />}
              selected={selected}
              setSelected={setSelected}
            />
            */}
            <Item
              title={t("Touch Campaign")}
              to="/touch"
              icon={<TouchAppOutlinedIcon />}
              selected={selected}
              setSelected={setSelected}
            />
            {/*---Developing People ----------------------------------------------------- */}
            <Typography
              variant="h6"
              color={colors.grey[300]}
              sx={{ m: "15px 0 5px 20px" }}
            >
              {isCollapsed ? undefined : "Developing People"}
            </Typography>
            <Item
              title="Feedback"
              to={`/feedback`}
              icon={<RecordVoiceOverOutlinedIcon />}
              selected={selected}
              setSelected={setSelected}
            />
            <Item
              title="PDI"
              to={`/pdi`}
              icon={<ModelTrainingOutlinedIcon />}
              selected={selected}
              setSelected={setSelected}
            />
            {/*---Configuration ----------------------------------------------------- */}
            <Typography
              variant="h6"
              color={colors.grey[300]}
              sx={{ m: "15px 0 5px 20px" }}
            >
              {isCollapsed ? undefined : "Configuration"}
            </Typography>
            <Item
              title={t("Manage Team")}
              to={`/team`}
              icon={<PeopleOutlinedIcon />}
              selected={selected}
              setSelected={setSelected}
            />
            <Item
              title={t("Manage Team Users")}
              to="/user"
              icon={<AutoModeOutlinedIcon />}
              selected={selected}
              setSelected={setSelected}
            />
            <Item
              title={t("Manage Account")}
              to={`/formAccount/${LoginProfile.getAccountId()}`}
              icon={<AccountBalanceOutlinedIcon />}
              selected={selected}
              setSelected={setSelected}
            />
            <Item
              title={t("Manage Login")}
              to={`/managelogin`}
              icon={<ManageAccountsOutlinedIcon />}
              selected={selected}
              setSelected={setSelected}
            />
          </Box>
        </Menu>
      </ProSidebar>
    </Box>
  );
};

export default Sidebar;

import { Box, Button, IconButton, Typography, linkClasses, useTheme } from "@mui/material";
import { tokens } from "../../theme";
import DownloadOutlinedIcon from "@mui/icons-material/DownloadOutlined";
import AutoAwesomeOutlinedIcon from '@mui/icons-material/AutoAwesomeOutlined';
import FavoriteBorderOutlinedIcon from '@mui/icons-material/FavoriteBorderOutlined';
import ElectricBoltOutlinedIcon from '@mui/icons-material/ElectricBoltOutlined';
import SafetyCheckOutlinedIcon from '@mui/icons-material/SafetyCheckOutlined';
import Header from "../../components/Header";
import LineChart from "../../components/LineChart";
import BarChart from "../../components/BarChart";
import StatBox from "../../components/StatBox";
import { useParams, useLocation } from "react-router-dom";
import { useEffect, useState } from "react";
import TeamProfile from "../../hooks/useTeam";
import { defaultMotionDamping } from "@nivo/core";
import '../../utils/i18n';
import { useTranslation } from 'react-i18next'

const mocklineChart = [
  {
    "id": 0,
    "data": [
        {
            "x": "",
            "y": 0
        },
    ]
  }
];

const mockKeyLineChart = ['People'];

const HappinessRadarDashboard = () => {
  const {t, i18n} = useTranslation();
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [classificationByAnswerType, setClassificationByAnswerType] = useState([]);
  const [keysHappinessRadar, setKeysHappinessRadar] = useState([]);
  const [total, setTotal] = useState([]);
  const [satisfactionClassification, setSatisfactionClassification] = useState([]);
  const [keysClassification, setKeysClassification] = useState([]);
  const [classification, setClassification] = useState("Career");
  const [lineChart, setLineChart] = useState([]);
  const [keysLineChart, setKeysLineChart] = useState([]);
  const [classificationLineChart, setClassificationLineChat] = useState([]);
  const [keysClassificationLineChart, setKeysClassificationLineChart] = useState([]);
  const [sizeLineChart,setSizeLineChart] = useState();
  const [sizeClassification, setSizeClassification] = useState();


  const location = useLocation();
  const api = process.env.REACT_APP_API_URL;

  const childToParent = (data) => {
    switch(data.id){
      case(t("People and Interactions")):
        setClassification(data.id);
        break;
      case(t("Quality of Life")):
        setClassification(data.id);
        break;
      case(t("Work Processes")):
        setClassification(data.id);
        break;
      case(t("Career")):
        setClassification(data.id);
        break;
      case(t("Operational")):
        setClassification(data.id);
        break;
      case(t("Tactical")):
        setClassification(data.id);
        break;
      case(t("Strategic")):
        setClassification(data.id);
        break;
      default:
        console.log('caiu no default (childParent)');
    };
  };

  const fetchClassificationLineChart = async () => {
    const urlTimeLine = `${api}/happinessradar/avgClassificationLinechart/team/${TeamProfile.getTeamID()}`;
    const options = {
      method: 'GET',
      headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "http://localhost:3000",
          "Access-Control-Allow-Methods": "POST, GET, PUT, DELETE",
          "Access-Control-Allow-Headers": "*",
          "Access-Control-Max-Age": "86400",
          "Authorization": `${sessionStorage.getItem('token')}`
        }
    };
    const respTimeLine = await fetch(urlTimeLine, options);
    const jsonTimeLine = await respTimeLine.json();
    setClassificationLineChat(jsonTimeLine);
    setKeysClassificationLineChart(handleKeysLineChart(jsonTimeLine));
    handleSizeClassification(jsonTimeLine);
  };

  useEffect(() => {
    const fetchItemLineChart = async () => {
      const fetchWithHeaders = async (url) => {
        const options = {
          method: 'GET',
          headers: {
              "Content-Type": "application/json",
              "Access-Control-Allow-Origin": "http://localhost:3000",
              "Access-Control-Allow-Methods": "POST, GET, PUT, DELETE",
              "Access-Control-Allow-Headers": "*",
              "Access-Control-Max-Age": "86400",
              "Authorization": `${sessionStorage.getItem('token')}`
          }
        };

        const resp = await fetch(url, options);
        return resp.json();
      }

      try{
        const [lineChartResponse] = await Promise.all([
          fetchWithHeaders(`${api}/dashboard/satisfactionitemlinechart/team/${TeamProfile.getTeamID()}/classification/${t(classification)}`)
        ]);
        
        setLineChart(lineChartResponse);
        handleSizeLineChart(lineChartResponse);
      }
      catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchItemLineChart();
    fetchClassificationLineChart();

  }, [TeamProfile.getTeamID(), t(classification)]);
  
  

  useEffect(() => {
    const fetchData = async () => {
      const fetchWithHeaders = async (url) => {
        const options = {
          method: 'GET',
          headers: {
              "Content-Type": "application/json",
              "Access-Control-Allow-Origin": "http://localhost:3000",
              "Access-Control-Allow-Methods": "POST, GET, PUT, DELETE",
              "Access-Control-Allow-Headers": "*",
              "Access-Control-Max-Age": "86400",
              "Authorization": `${sessionStorage.getItem('token')}`
          }
        };

        const resp = await fetch(url, options);
        return resp.json();
      }
      try {
        const [classificationByAnswerTypeResponse,classificationResponse] = await Promise.all([
          fetchWithHeaders(`${api}/dashboard/AvgBigNumbersByClassification/team/${TeamProfile.getTeamID()}`),
          fetchWithHeaders(`${api}/dashboard/satisfationItembyClassification/team/${TeamProfile.getTeamID()}/classification/${t(classification)}`)
        ]);
    
        setClassificationByAnswerType(classificationByAnswerTypeResponse);
        setKeysHappinessRadar(handleKeysDetail(classificationByAnswerTypeResponse));
        setSatisfactionClassification(classificationResponse);
        setKeysClassification(handleKeysDetail(classificationResponse));
      }
      catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, [TeamProfile.getTeamID(), location.pathname, classification]);

  const handleKeysDetail = (barChart) => {
    const key = new Array();
    if(barChart){
      barChart.map((itens) =>{
        if(!key.includes(itens.classification)){
          key.push(itens.classification);
        }
        return itens.classification;
      });
    }
   return key;
  };

  const handleKeysLineChart = (barChart) => {
    const key = new Array();
    if(barChart){
      barChart.map((itens) =>{
        if(!key.includes(itens.id)){
          key.push(itens.id);
        }
        return itens.id;
      });
    }
   return key;
  };

  let people_score;
  let qualityLife_score;
  let workProcess_score;
  let career_score;
  let total_score; 

  if(classificationByAnswerType) {
    classificationByAnswerType.map((itens) => {
      switch(itens.classification){
        case t('People and Interactions'):
          people_score = itens.score;
          break;
        case t('Quality of Life'):
          qualityLife_score = itens.score;
          break;
        case t('Work Processes'):
          workProcess_score = itens.score;
          break;
        case t('Career'):
          career_score = itens.score;
          break;
        default:
          console.log(`Sorry, we are out of ${itens.classification}`);
      }
      return null;
    });
  }
  

  if(total){
    total.map((itens) =>{
      total_score = itens.avgAll;
    });
  }

  const handleIncrease = (score) => {
    let result = (parseFloat(score) / 5) * 100 ;
    return result
  };

  const handleToFixedIncrease = (score) => {
    return score.toFixed(2);
  };

  const handleSizeLineChart = (data) => {
    data.map((item) =>{
      setSizeLineChart(item.data.length);
      return item.data.length;
    });
  };

  const handleSizeClassification = (data) => {
    data.map((item) =>{
      setSizeClassification(item.data.length);
      return item.data.length;
    });
  };

  const dataRadarChart = [
    {
      subject: "Happiness Radar",
      A: people_score,
      fullMark: 5
    },
    {
      subject: "Energy",
      A: qualityLife_score,
      fullMark: 5
    },
    {
      subject: "Motivation",
      A: workProcess_score,
      fullMark: 5
    },
    {
      subject: "Safety",
      A: career_score,
      fullMark: 5
    },
  ];

  return (
    <Box m="20px">
      {/* HEADER */}
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Header title={t("HAPPINESS RADAR DASHBOARD")} subtitle={t("Welcome to happiness radar level dashboard")} />
        <Box>
          <Button
            sx={{
              backgroundColor: colors.blueAccent[700],
              color: colors.grey[100],
              fontSize: "14px",
              fontWeight: "bold",
              padding: "10px 20px",
            }}
          >
            <DownloadOutlinedIcon sx={{ mr: "10px" }} />
            Download Reports
          </Button>
        </Box>
      </Box>

      {/* GRID & CHARTS */}
      <Box
        display="grid"
        gridTemplateColumns="repeat(12, 1fr)"
        gridAutoRows="140px"
        gap="20px"
      >
        {/* ROW 1 */}
        <Box
          gridColumn="span 3"
          backgroundColor={colors.primary[400]}
          display="flex"
          alignItems="center"
          justifyContent="center"
          borderRadius="10px"
        >
          <StatBox
            title={people_score ? handleToFixedIncrease(people_score) : "NaN"}
            subtitle={t("People and Interactions")}
            progress={handleIncrease(people_score) / 100}
            increase={handleIncrease(people_score).toFixed(0) + "%"}
            icon={
              <FavoriteBorderOutlinedIcon
                sx={{ color: colors.greenAccent[600], fontSize: "26px" }}
              />
            }
          />
        </Box>
        <Box
          gridColumn="span 3"
          backgroundColor={colors.primary[400]}
          display="flex"
          alignItems="center"
          justifyContent="center"
          borderRadius="10px"
        >
          <StatBox
            title={qualityLife_score ? handleToFixedIncrease(qualityLife_score) : "Pesquisa não respondida para esse time"}
            subtitle={t("Quality of Life")}
            progress={handleIncrease(qualityLife_score) / 100}
            increase={handleIncrease(qualityLife_score).toFixed(0) + "%"}
            icon={
              <ElectricBoltOutlinedIcon
                sx={{ color: colors.greenAccent[600], fontSize: "26px" }}
              />
            }
          />
        </Box>
        <Box
          gridColumn="span 3"
          backgroundColor={colors.primary[400]}
          display="flex"
          alignItems="center"
          justifyContent="center"
          borderRadius="10px"
        >
          <StatBox
            title={workProcess_score ? handleToFixedIncrease(workProcess_score) : "NaN"}
            subtitle={t("Work Processes")}
            progress={handleIncrease(workProcess_score) / 100}
            increase={handleIncrease(workProcess_score).toFixed(0) + "%"}
            icon={
              <AutoAwesomeOutlinedIcon
                sx={{ color: colors.greenAccent[600], fontSize: "26px" }}
              />
            }
          />
        </Box>
        <Box
          gridColumn="span 3"
          backgroundColor={colors.primary[400]}
          display="flex"
          alignItems="center"
          justifyContent="center"
          borderRadius="10px"
        >
          <StatBox
            title={career_score ? handleToFixedIncrease(career_score) : "NaN"}
            subtitle={t("Career")}
            progress={handleIncrease(career_score) / 100}
            increase={handleIncrease(career_score).toFixed(0) + "%"}
            icon={
              <SafetyCheckOutlinedIcon
                sx={{ color: colors.greenAccent[600], fontSize: "26px" }}
              />
            }
          />
        </Box>

        {/* ROW 2 */}
        <Box
          gridColumn="span 6"
          gridRow="span 2"
          backgroundColor={colors.primary[400]}
          borderRadius="10px"
        >
          <Typography
            variant="h5"
            fontWeight="600"
            sx={{ padding: "30px 30px 0 30px" }}
          >
            {t("Satisfaction level by classification")}
          </Typography>
          <Box height="85%" mt="-20px">
            <BarChart
              isDashboard={true}
              data={classificationByAnswerType ? classificationByAnswerType : null}
              keys={keysHappinessRadar ? keysHappinessRadar : null}
              indexBy="classification"
              groupMode="stacked"
              childToParent={childToParent}
              interactive={true}
              maxValue={5} />
          </Box>
        </Box>
        <Box
          gridColumn="span 6"
          gridRow="span 2"
          backgroundColor={colors.primary[400]}
          borderRadius="10px"
        >
          <Typography
            variant="h5"
            fontWeight="600"
            sx={{ padding: "30px 30px 0 30px" }}
          >
            {t("Satisfaction level by Item")}
          </Typography>
          <Box height="85%" mt="-20px">
            <BarChart
              isDashboard={true}
              data={satisfactionClassification ? satisfactionClassification : null}
              keys={keysClassification ? keysClassification : null}
              indexBy="classification"
              groupMode="stack"
              interactive={false}
              maxValue={5}
              />
          </Box>
        </Box>

        {/* ROW 3 */}
        <Box
          gridColumn="span 6"
          gridRow="span 2"
          backgroundColor={colors.primary[400]}
          borderRadius="10px"
        >
          <Typography
            variant="h5"
            fontWeight="600"
            sx={{ padding: "30px 30px 0 30px" }}
          >
            {t("Satisfaction time line by classification")}
          </Typography>
          <Box height="85%" mt="-20px">
            <LineChart
              isDashboard={false}
              data={classificationLineChart ? classificationLineChart : mocklineChart}
              keys={keysClassificationLineChart ? keysClassificationLineChart : mockKeyLineChart}
              maxValue={5}
              tickRotation={sizeLineChart && sizeLineChart > 10 ? -15 : 0}
            />
          </Box>
        </Box>
        <Box
          gridColumn="span 6"
          gridRow="span 2"
          backgroundColor={colors.primary[400]}
          borderRadius="10px"
        >
          <Typography
            variant="h5"
            fontWeight="600"
            sx={{ padding: "30px 30px 0 30px" }}
          >
            {t("Satisfaction time line by Item")}
          </Typography>
          <Box height="85%" mt="-20px">
            <LineChart 
              isDashboard={false}
              data={lineChart ? lineChart : mocklineChart}
              keys={keysLineChart ? keysLineChart : mockKeyLineChart}
              maxValue={5}
              tickRotation={sizeClassification && sizeClassification > 10 ? -15 : 0 }
              />
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default HappinessRadarDashboard;

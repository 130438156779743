var LoginProfile = (function() {
    var loginLoginId = null;
    var loginFullName = null;
    var loginEmail = null;
    var loginProductId = null;
    var loginProductDuration = null;
    var loginAccountID = null;
    var loginAccountUUID = null;

  
    var getLoginId = function() {
      return loginLoginId;
    }

    var setLoginId = function(id) {
      loginLoginId = id;
    }

    var getFullName = function() {
      return loginFullName;    // Or pull this from cookie/localStorage
    };
  
    var setFullName = function(fullName) {
        loginFullName = fullName;     
      // Also set this in cookie/localStorage
    };

    var getEmail = function() {
      return loginEmail;
    };

    var setEmail = function(email) {
      loginEmail = email;
    };
    
    var getProductId = function() {
      return loginProductId;
    };

    var setProductId = function(productId) {
      loginProductId = productId;
    };

    var getProductDuration = function() {
      return loginProductDuration;
    };

    var setProductDuration = function(productDuration) {
      loginProductDuration = productDuration;
    };

    var getAccountId = function(){
      return loginAccountID;
    };

    var setAccountId = function(accountId){
      loginAccountID = accountId;
    };

    var getAccountUUID = function(){
      return loginAccountUUID;
    };

    var setAccountUUID = function(accountUUID){
      loginAccountUUID = accountUUID;
    };

    var logOut = function() {
      loginLoginId = null;
      loginFullName = null;
      loginEmail = null;
      loginProductId = null;
      loginProductDuration = null;
      loginAccountID = null;
      loginAccountUUID = null;
    }
    
    return {
      setLoginId : setLoginId,
      getLoginId : getLoginId,
      getFullName: getFullName,
      setFullName: setFullName,
      getEmail : getEmail,
      setEmail : setEmail,
      getProductId : getProductId,
      setProductId : setProductId,
      getProductDuration : getProductDuration,
      setProductDuration : setProductDuration,
      getAccountId : getAccountId,
      setAccountId : setAccountId,
      setAccountUUID : setAccountUUID,
      getAccountUUID : getAccountUUID,
      logOut: logOut
    };
  
  })();
  
  export default LoginProfile;
import { Box, TextField, Select, MenuItem, FormControl, InputLabel } from "@mui/material";
import { Formik } from "formik";
import * as yup from "yup";
import useMediaQuery from "@mui/material/useMediaQuery";
import Header from "../../../components/Header";
import { mockPaises } from '../../../data/mockPaises copy'
import SnackBars from "../../../components/CustomizedSnackBars";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import {useFetch} from '../../../hooks/useFetch';
import LoginProfile from "../../../hooks/useLoginUser";
import '../../../utils/i18n';
import { useTranslation } from "react-i18next";

const FormLanguage = () => {
  const { t, i18n } = useTranslation();
  const isNonMobile = useMediaQuery("(min-width:600px)");
  const [currentLanguage, setCurrentLanguage] = useState();

  //recebe id do account para carregar o form para edição\
  const api = process.env.REACT_APP_API_URL;
  //----------------------------------------------------

  //seta url para cadastro (POST) e grava o retorno da API no Resp
  const [resp, setResp] = useState(null);
  //-------------------------------------------------------------

  //seta o resp como null sempre que o form for alterado
  const handleFormChange = () => {
    setResp(null);
  };
  //--------------------------------------------------

  initialValues.language = `${t(currentLanguage) ? t(currentLanguage) : ""}`;

  const fetchData = async () => {
    const url = `${api}/language/${LoginProfile.getAccountId()}`;
    const options = {
      method: 'GET',
      headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "http://localhost:3000",
          "Access-Control-Allow-Methods": "POST, GET, PUT, DELETE",
          "Access-Control-Allow-Headers": "*",
          "Access-Control-Max-Age": "86400",
          "Authorization": sessionStorage.getItem('token')
        }
    };
    const resp = await fetch(url, options);
    const json = await resp.json();
    setCurrentLanguage(json.language);
  };

  useEffect(() => {
    fetchData();
  },[]);

  const changeLanguage = value => {
    i18n.changeLanguage(value)
    .then(() => {
      setCurrentLanguage(value);
      localStorage.setItem('language',value);
    })
    .catch((err) => {
      console.log(err);
    })
  };


  //Handle de submit do form
  const handleFormSubmit = async (values) => {
    const accountUrl = `${api}/language/cadastro`;
    
    const lang = {
      language: values.language,
      account_id : LoginProfile.getAccountId(),
    };
    
    const reqLang = await fetch(accountUrl,{
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "http://localhost:3000",
        "Access-Control-Allow-Methods": "POST, GET, PUT, DELETE",
        "Access-Control-Allow-Headers": "*",
        "Access-Control-Max-Age": "86400",
      },
      body: JSON.stringify(lang),
    });
    setResp(reqLang.status);
    if(reqLang.ok){
      changeLanguage(values.language);
    }   
  };
  //------------------------------------------------

  return (
    <Box m="20px">
      <Header title={t("MANAGE LANGUAGE OF ACCOUNT")} subtitle={t("If you change the language, it will apply to all account users")} />

      <Formik
        onSubmit={handleFormSubmit}
        initialValues={initialValues}
        validationSchema={checkoutSchema}
      >
        {({
          values,
          errors,
          touched,
          handleBlur,
          handleChange,
          handleSubmit,
        }) => (
          <form onSubmit={handleSubmit} onChange={handleFormChange}>
            <Box
              display="grid"
              gap="30px"
              gridTemplateColumns="repeat(4, minmax(0, 1fr))"
              sx={{
                "& > div": { gridColumn: isNonMobile ? undefined : "span 4" },
              }}
            >
              <FormControl>
                <Select
                  fullWidth
                  variant="filled"
                  label={t("Language")}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={t(values.language)}
                  name="language"
                  error={!!touched.language && !!errors.language}
                  helperText={touched.language && errors.language}
                  sx={{ gridColumn: "span 2" }}
                  >
                    <MenuItem value="br">{t("Portuguese")}</MenuItem>
                    <MenuItem value="us">{t("English")}</MenuItem>
                    <MenuItem value="es">{t("Spanish")}</MenuItem>
                </Select>
              </FormControl>
            </Box>
            <SnackBars buttonName={t("SAVE LANGUAGE")} buttonType="submit" resp={resp} />
          </form>
        )}
      </Formik>
    </Box>
  );
};

const phoneRegExp =
  /^((\+[1-9]{1,4}[ -]?)|(\([0-9]{2,3}\)[ -]?)|([0-9]{2,4})[ -]?)*?[0-9]{3,4}[ -]?[0-9]{3,4}$/;

const checkoutSchema = yup.object().shape({
  language: yup.string().required("required")
});
const initialValues = {
  language: "",
};

export default FormLanguage;
import { Box, TextField, useTheme, Typography } from "@mui/material";
import { Formik } from "formik";
import * as yup from "yup";
import useMediaQuery from "@mui/material/useMediaQuery";
import Header from "../../../components/Header.jsx";
import SnackBars from "../../../components/CustomizedSnackBars.jsx";
import { useState } from "react";
import { useParams } from "react-router-dom";
import { tokens } from "../../../theme.js";
import CustomizedRating from "../../../components/CustomizedRating.jsx";
import { useNavigate } from "react-router-dom";
import '../../../utils/i18n.js';
import { useTranslation } from 'react-i18next';


const FormAssessment = ({title, subTitle}) => {
  const {t, i18n } = useTranslation();
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const isNonMobile = useMediaQuery("(min-width:600px)");
  const [resp, setResp] = useState(null);

  const api = process.env.REACT_APP_API_URL;

  const { id } = useParams();
  const navigate = useNavigate();

  const [data,setData] = useState([]);

  let classif = new Map([]);
  let keys = new Map([]);

  const childToParent = (score, item, classification) => {
    if(keys.has(item)){
      keys.set(item,score);
      classif.set(item,classification);
    }
    else{
      keys.set(item, score);
      classif.set(item,classification);
    }
  };

  const handleDataHora = (dataHora) => {
    let dataFormatada = new Date();
    dataFormatada = dataHora.split('/').reverse().join('-');
    return dataFormatada;
  };

  //Handle de submit do form
  const handleFormSubmit = async (values) => {
    const url = `${api}/feedback/assessment/cadastro`;
    
    keys.forEach(async function(score,item){
      const myDate = handleDataHora(new Date(Date.now()).toLocaleString().split(',')[0].trim());
      const myHour = new Date(Date.now()).toLocaleString().split(',')[1].trim();
      const answer = {
        item : item,
        feeling : "",
        score : parseFloat(score),
        classification : classif.get(item),
        answer_type : "assessment",
        create_date : myDate,
        feedback_id : parseInt(id)
      };
      const request = await fetch(url,{
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "http://localhost:3000",
          "Access-Control-Allow-Methods": "POST, GET, PUT, DELETE",
          "Access-Control-Allow-Headers": "*",
          "Access-Control-Max-Age": "86400",
        },
        body: JSON.stringify(answer),
      });
      setResp(request.status);
      if(request.status >= 200 && request.status <= 299){
        navigate("/feedback");
      }
    });    
  };
  //------------------------------------------------

  return (
    <Box m="20px">
      <Header title={t("ASSESSMENT")} subtitle={t("Carry out employee assessment")} />

      <Formik
        onSubmit={handleFormSubmit}
        initialValues={initialValues}
        //validationSchema={checkoutSchema}
      >
        {({
          values,
          errors,
          touched,
          handleBlur,
          handleChange,
          handleSubmit,
        }) => (
          <form onSubmit={handleSubmit} >
            <Box
              display="grid"
              gap="8px"
              gridTemplateColumns="repeat(12, minmax(0, 1fr))"
              sx={{
                "& > div": { gridColumn: isNonMobile ? undefined : "span 12" },
              }}
            >
              <Box
                sx={{
                  gridColumn: "span 4",
                }}
              >
                <Typography 
                  component="legend"
                  variant="h4"
                >
                  {t("Committed")}
                </Typography>
                <CustomizedRating
                gridColumn="span 4"
                defaultValue={0}
                precision={0.5}
                size="large"
                title={t("Commits to completing assigned tasks and meets deadlines.")}
                name={t("Committed")}
                childToParent={childToParent}
                classification={t("Softskill")}
              />
              </Box>
              <Box
                sx={{
                  gridColumn: "span 4",
                }}
              >
                <Typography 
                  component="legend"
                  variant="h4"
                >
                  {t("Proactivity")}
                </Typography>
                <CustomizedRating
                  gridColumn="span 4"
                  defaultValue={0}
                  precision={0.5}
                  size="large"
                  title={t("Acts in advance to avoid problems, presents alternatives and is participative.")}
                  name={t("Proactivity")}
                  childToParent={childToParent}
                  classification={t("Softskill")}
                />
              </Box>
              <Box
                sx={{
                  gridColumn: "span 4",
                }}
              >
                <Typography 
                  component="legend"
                  variant="h4"
                >
                  {t("Communication")}
                </Typography>
                <CustomizedRating
                  gridColumn="span 4"
                  defaultValue={0}
                  precision={0.5}
                  size="large"
                  title={t("Communicates ideas clearly and effectively. Establishes relationships of trust.")}
                  name={t("Communication")}
                  childToParent={childToParent}
                  classification={t("Softskill")}
                />
              </Box>
              <Box
                sx={{
                  gridColumn: "span 4",
                }}
              >
                <Typography 
                  component="legend"
                  variant="h4"
                >
                  {t("Integrity and respect")}
                </Typography>
                <CustomizedRating
                gridColumn="span 4"
                defaultValue={0}
                precision={0.5}
                size="large"
                title={t("Complies with the norms, values ​​and rules of conduct associated with the culture.")}
                name={t("Integrity and respect")}
                childToParent={childToParent}
                classification={t("Softskill")}
              />
              </Box>
              <Box
                sx={{
                  gridColumn: "span 4",
                }}
              >
                <Typography 
                  component="legend"
                  variant="h4"
                >
                  {t("Flexibility and adaptation to changes")}
                </Typography>
                <CustomizedRating
                  gridColumn="span 4"
                  defaultValue={0}
                  precision={0.5}
                  size="large"
                  title={t("Ability to adapt to new operating models and market demands.")}
                  name={t("Flexibility and adaptation to changes")}
                  childToParent={childToParent}
                  classification={t("Softskill")}
                />
              </Box>
              <Box
                sx={{
                  gridColumn: "span 4",
                }}
              >
                <Typography 
                  component="legend"
                  variant="h4"
                >
                  {t("Collaboration")}
                </Typography>
                <CustomizedRating
                gridColumn="span 4"
                defaultValue={0}
                precision={0.5}
                size="large"
                title={t("Works together with the team, cooperating to achieve common goals.")}
                name={t("Collaboration")}
                childToParent={childToParent}
                classification={t("Softskill")}
              />
              </Box>
              <Box
                sx={{
                  gridColumn: "span 4",
                }}
              >
                <Typography 
                  component="legend"
                  variant="h4"
                >
                  {t("Technical knowledge")}
                </Typography>
                <CustomizedRating
                gridColumn="span 4"
                defaultValue={0}
                precision={0.5}
                size="large"
                title={t("Mastery of tools and technologies relevant to the role.")}
                name={t("Technical knowledge")}
                childToParent={childToParent}
                classification={t("Hardskill")}
              />
              </Box>
              <Box
                sx={{
                  gridColumn: "span 4",
                }}
              >
                <Typography 
                  component="legend"
                  variant="h4"
                >
                  {t("Focus on Deliveries")}
                </Typography>
                <CustomizedRating
                gridColumn="span 4"
                defaultValue={0}
                precision={0.5}
                size="large"
                title={t("Ability to meet deadlines and established goals.")}
                name={t("Focus on Deliveries")}
                childToParent={childToParent}
                classification={t("Hardskill")}
              />
              </Box>
              <Box
                sx={{
                  gridColumn: "span 4",
                }}
              >
                <Typography 
                  component="legend"
                  variant="h4"
                >
                  {t("Focus on Quality")}
                </Typography>
                <CustomizedRating
                gridColumn="span 4"
                defaultValue={0}
                precision={0.5}
                size="large"
                title={t("Commitment to excellence and precision in the work performed.")}
                name={t("Focus on Quality")}
                childToParent={childToParent}
                classification={t("Hardskill")}
              />
              </Box>
              <Box
                sx={{
                  gridColumn: "span 4",
                }}
              >
                <Typography 
                  component="legend"
                  variant="h4"
                >
                  {t("Process Mastery")}
                </Typography>
                <CustomizedRating
                gridColumn="span 4"
                defaultValue={0}
                precision={0.5}
                size="large"
                title={t("Knowledge and effective application of internal processes and procedures.")}
                name={t("Process Mastery")}
                childToParent={childToParent}
                classification={t("Hardskill")}
              />
              </Box>
              <Box
                sx={{
                  gridColumn: "span 4",
                }}
              >
                <Typography 
                  component="legend"
                  variant="h4"
                >
                  {t("Problem Solving Ability")}
                </Typography>
                <CustomizedRating
                gridColumn="span 4"
                defaultValue={0}
                precision={0.5}
                size="large"
                title={t("Ability to identify problems and implement effective solutions.")}
                name={t("Problem Solving Ability")}
                childToParent={childToParent}
                classification={t("Hardskill")}
              />
              </Box>
              <Box
                sx={{
                  gridColumn: "span 4",
                }}
              >
                <Typography 
                  component="legend"
                  variant="h4"
                >
                  {t("Data analysis")}
                </Typography>
                <CustomizedRating
                gridColumn="span 4"
                defaultValue={0}
                precision={0.5}
                size="large"
                title={t("Ability to analyze and interpret relevant data for decision making.")}
                name={t("Data analysis")}
                childToParent={childToParent}
                classification={t("Hardskill")}
              />
              </Box>
            </Box>
            <SnackBars buttonName={t("FINISH")} buttonType="submit" resp={resp} />
          </form>
        )}
      </Formik>
    </Box>
  );
};

const phoneRegExp =
  /^((\+[1-9]{1,4}[ -]?)|(\([0-9]{2,3}\)[ -]?)|([0-9]{2,4})[ -]?)*?[0-9]{3,4}[ -]?[0-9]{3,4}$/;

const checkoutSchema = yup.object().shape({
  safety_check: yup.string().required("required"),
});
const initialValues = {
  safety_check: "",
};

export default FormAssessment;
import { Box, TextField,Select,FormControl,InputLabel,MenuItem } from "@mui/material";
import { Formik } from "formik";
import * as yup from "yup";
import useMediaQuery from "@mui/material/useMediaQuery";
import Header from "../../../components/Header";
import SnackBars from "../../../components/CustomizedSnackBars";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import {useFetch} from '../../../hooks/useFetch';
import TeamProfile from "../../../hooks/useTeam";
import '../../../utils/i18n';
import { useTranslation } from "react-i18next";
import InputMask from "react-input-mask";


const FormTouch = () => {
  const { t, i18n } = useTranslation();
  const isNonMobile = useMediaQuery("(min-width:600px)");
  const [resp, setResp] = useState(null);
  const [team, setTeam] = useState();

  const api = process.env.REACT_APP_API_URL;

  //recebe id do account para carregar o form para edição
  const { id } = useParams();
  //----------------------------------------------------

  const urlGetData = `${api}/campaign/${id}`;
  const { data } = useFetch(urlGetData);

  initialValues.campaign_name = `${data.campaign_name ? data.campaign_name : ""}`;
  initialValues.team = `${TeamProfile.getTeamName() ? TeamProfile.getTeamName() : ""}`;
  initialValues.people_target = `${data.people_target ? data.people_target : ""}`;
  initialValues.initial_date = `${data.initial_date ? data.initial_date : ""}`;
  initialValues.end_date = `${data.end_date ? data.end_date : ""}`;
  initialValues.objective = `${data.objective ? data.objective : ""}`;

  //seta o resp como null sempre que o form for alterado
  const handleFormChange = () => {
    setResp(null);
  };

  const handleDataHora = (dataHora) => {
    let dataFormatada = new Date();
    dataFormatada = dataHora.split('/').reverse().join('-');
    return dataFormatada;
  };

  //--------------------------------------------------


  //Handle de submit do form
  const handleFormSubmit = async (values) => {
    const url = `${api}/campaign/cadastro`;

    const campaign = {
      campaign_id : id,
      campaign_name : values.campaign_name,
      objective : values.objective,
      initial_date : handleDataHora(values.initial_date),
      end_date : handleDataHora(values.end_date),
      people_target : values.people_target,
      campaign_type : values.campaign_type,
      status :  "não enviado",
      team_id: TeamProfile.getTeamID()
    };
    const request = await fetch(url,{
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "http://localhost:3000",
        "Access-Control-Allow-Methods": "POST, GET, PUT, DELETE",
        "Access-Control-Allow-Headers": "*",
        "Access-Control-Max-Age": "86400",
        "Authorization": `${sessionStorage.getItem('token')}`
      },
      body: JSON.stringify(campaign),
    });
    setResp(request.status);
    
  };

  const maskDate = () => {
    switch(localStorage.getItem('language')){
      case "br":
        return '99/99/9999';
      case "es":
        return '9999-99-99';
      case "us":
        return '9999-99-99';
    }
    return 0;
  };
  
  const handleLabel = () => {
    if(id){
      return "Update Touch Campaign";
    }
      return "Create Touch Campaign";
  };

  return (
    <Box m="20px">
      <Header title={t(handleLabel().toUpperCase())} subtitle={t("Create or edit Touch Campaign")} />

      <Formik
        onSubmit={handleFormSubmit}
        initialValues={initialValues}
        validationSchema={checkoutSchema}
      >
        {({
          values,
          errors,
          touched,
          handleBlur,
          handleChange,
          handleSubmit,
        }) => (
          <form onSubmit={handleSubmit} onChange={handleFormChange}>
            <Box
              display="grid"
              gap="30px"
              gridTemplateColumns="repeat(4, minmax(0, 1fr))"
              sx={{
                "& > div": { gridColumn: isNonMobile ? undefined : "span 4" },
              }}
            >
              <TextField
                fullWidth
                variant="filled"
                type="text"
                label={t("Campaign Name")}
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.campaign_name}
                name="campaign_name"
                error={!!touched.campaign_name && !!errors.campaign_name}
                helperText={touched.campaign_name && errors.campaign_name}
                sx={{ gridColumn: "span 4" }}
              />
              <TextField
                disabled
                fullWidth
                variant="filled"
                type="text"
                label={t("Team")}
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.team}
                name="team"
                error={!!touched.team && !!errors.team}
                helperText={touched.team && errors.team}
                sx={{ gridColumn: "span 4" }}
              />
              <InputMask
                mask={maskDate()}
                maskPlaceholder={null} // Isso remove o hint de máscara do campo
                onChange={handleChange}
                value={values.initial_date}
                name="initial_date"
                error={!!(touched.initial_date && errors.initial_date)} // Considere o email como erro se houver um erro retornado pela API
                helperText={touched.initial_date && errors.initial_date} 
                sx={{ gridColumn: "span 2" }}
              >
                {(inputProps) => <TextField {...inputProps} fullWidth variant="filled" type="text" label={t("Realization Date")} />}
              </InputMask>
              <InputMask
                mask={maskDate()}
                maskPlaceholder={null} // Isso remove o hint de máscara do campo
                onChange={handleChange}
                value={values.end_date}
                name="end_date"
                error={!!(touched.end_date && errors.end_date)} // Considere o email como erro se houver um erro retornado pela API
                helperText={touched.end_date && errors.end_date} 
                sx={{ gridColumn: "span 2" }}
              >
                {(inputProps) => <TextField {...inputProps} fullWidth variant="filled" type="text" label={t("Realization Date")} />}
              </InputMask>
              <TextField
                fullWidth
                variant="filled"
                type="text"
                label={t("Objective")}
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.objective}
                name="objective"
                error={!!touched.objective && !!errors.objective}
                helperText={touched.objective && errors.objective}
                sx={{ gridColumn: "span 4" }}
                multiline
                rows={4}
              />
            </Box>
            <SnackBars buttonName={t(handleLabel().toUpperCase())} buttonType="submit" resp={resp} />
          </form>
        )}
      </Formik>
    </Box>
  );
};

const phoneRegExp =
  /^((\+[1-9]{1,4}[ -]?)|(\([0-9]{2,3}\)[ -]?)|([0-9]{2,4})[ -]?)*?[0-9]{3,4}[ -]?[0-9]{3,4}$/;

const checkoutSchema = yup.object().shape({
  campaign_name: yup.string().required("required"),
  initial_date: yup.string().required("required"),
  end_date: yup.string().required("required"),
});
const initialValues = {
  campaign_name: "",
  team: "",
  people_target: "",
  initial_date: "",
  end_date: "",
  objective: ""
};

export default FormTouch;
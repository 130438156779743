import { useState } from "react";
import { BrowserRouter ,Routes, Route, useLocation, useNavigate } from "react-router-dom";
import Topbar from "./scenes/global/Topbar";
import Sidebar from "./scenes/global/Sidebar";
import Dashboard from "./scenes/dashboard";
import HappinessRadarDashboard from "./scenes/dashboard/happinessradar";
import EnergyMotivationDashboard from "./scenes/dashboard/energymotivation";
import SafetyCheckDashboard from "./scenes/dashboard/safetycheck";
import Team from "./scenes/team";
import Invoices from "./scenes/invoices";
import Iteration from "./scenes/iteration";
import Touch from "./scenes/touch";
import FormTouch from "./scenes/touch/formTouch";
import FormSafetyCheck from "./scenes/safetyCheck";
import FormEnergyMotivation from "./scenes/energymotivation";
import FormHappinessRadar from "./scenes/happinessradar";
import FormAccount from "./scenes/account/formAccount";
import Contacts from "./scenes/contacts";
import Bar from "./scenes/bar";
import Form from "./scenes/form";
import FormTeam from "./scenes/team/formTeam";
import FormIteration from "./scenes/iteration/formIteration";
import { CssBaseline, Switch, ThemeProvider } from "@mui/material";
import { ColorModeContext, useMode } from "./theme";
import Satisfaction from "./scenes/dashboard/satisfaction";
import ThankYouPage from "./scenes/redirect";
import AlternateTopbar from "./scenes/global/AlternateTopbar";
import LoginPage from "./scenes/login";
import { GoogleOAuthProvider } from '@react-oauth/google';
//import { ProtectedRoute } from "./components/ProtecedRoute";
import TeamProfile from "./hooks/useTeam";
import User from "./scenes/user";
import FormUser from "./scenes/user/formUser";
import FormCadLogin from "./scenes/login/formCadLogin";
import FormCadAccount from "./scenes/login/formCadAccount";
import { AuthProvider} from './components/Auth';
import { CustomRoute } from "./components/CustomRoute";
import Recovery from "./scenes/login/recovery";
import Reset from "./scenes/login/reset";
import HeatMapDashboard from "./scenes/dashboard/heatmap";
import FormInvite from "./scenes/account/formAccount/invite";
import FormLanguage from "./scenes/account/formAccount/language";
import Account from "./scenes/account";
import FormManageLogin from "./scenes/account/formLogin";
import OnBoarding from "./scenes/onboarding/onboarding";
import Feedback from "./scenes/feedback";
import FormFeedback from "./scenes/feedback/formFeedback";
import FormCadastroFeedback from "./scenes/feedback/formFeedback/cadastro";
import FormAssessment from "./scenes/feedback/formFeedback/assessment";
import FormPdi from "./scenes/pdi/cadastro";
import ActionPlan from "./scenes/pdi/actionplan";
import ListPDI from "./scenes/pdi";
import FormInsertActivity from "./scenes/pdi/activity";



function App() {
  const [theme, colorMode] = useMode();
  const [isSidebar, setIsSidebar] = useState(true);
  
  const location = useLocation();
  const navigate = useNavigate();

  const excludedRoutes = ["/formSafetyCheck","/formEnergymotivation","/formHappinessradar"
  ,"/redirect","/login"];
  const shouldRenderSidebar = !excludedRoutes.some(route => location.pathname.includes(route));

  return (
    <AuthProvider>
      <ColorModeContext.Provider value={colorMode}>
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <div className="app">
            {shouldRenderSidebar &&  <Sidebar isSidebar={isSidebar} />}
            <main className="content">
            {shouldRenderSidebar ? <Topbar setIsSidebar={setIsSidebar} /> : <AlternateTopbar setIsSidebar={setIsSidebar} />}
              <Routes>
                <Route path="/" element={<CustomRoute> <Dashboard /> </CustomRoute>} />
                <Route path="/invite" element={<CustomRoute> <FormInvite /> </CustomRoute>} />
                <Route path="/formAccount" element={<CustomRoute> <FormAccount /> </CustomRoute>} />
                <Route path="/formAccount/:id" element={<CustomRoute> <FormAccount /> </CustomRoute>} />
                <Route path="/managelogin" element={<CustomRoute> <Account /> </CustomRoute>} />
                <Route path="/managelogin/userLogin" element={<CustomRoute> <FormManageLogin /> </CustomRoute>} />
                <Route path="/managelogin/userLogin/:id" element={<CustomRoute> <FormManageLogin /> </CustomRoute>} />
                <Route path="/team" element={<CustomRoute> <Team /> </CustomRoute>} />
                <Route path="/team/formTeam" element={<CustomRoute> <FormTeam /> </CustomRoute>} />
                <Route path="/team/formTeam/:id" element={<CustomRoute> <FormTeam /> </CustomRoute>} />
                <Route path="/touch" element={<CustomRoute> <Touch /> </CustomRoute>} />
                <Route path="/formTouch" element={<CustomRoute> <FormTouch /> </CustomRoute>} />
                <Route path="/formTouch/:id" element={<CustomRoute> <FormTouch /> </CustomRoute>} />
                <Route path="/dashboard/satisfaction" element={<CustomRoute> <Satisfaction /> </CustomRoute>} />
                <Route path="/dashboard/happinessradar" element={<CustomRoute> <HappinessRadarDashboard /> </CustomRoute>} />
                <Route path="/dashboard/energymotivation" element={<CustomRoute> <EnergyMotivationDashboard /> </CustomRoute>} />
                <Route path="/dashboard/safetycheck" element={<CustomRoute> <SafetyCheckDashboard /> </CustomRoute>} />
                <Route path="/dashboard/heatmap" element={<CustomRoute> <HeatMapDashboard /> </CustomRoute>} />
                <Route path="/user" element={<CustomRoute> <User /> </CustomRoute>} />
                <Route path="/user/formUser" element={<CustomRoute> <FormUser /> </CustomRoute>} />
                <Route path="/user/formUser/:id" element={<CustomRoute> <FormUser /> </CustomRoute>} />
                <Route path="/account/language" element={<CustomRoute> <FormLanguage /> </CustomRoute>} />
                <Route path="/onboarding" element={<CustomRoute> <OnBoarding /> </CustomRoute>} />
                <Route path="/feedback" element={<CustomRoute> <Feedback /> </CustomRoute>} />
                <Route path="/feedback/cadastro" element={<CustomRoute> <FormCadastroFeedback /> </CustomRoute>} />
                <Route path="/feedback/cadastro/:id" element={<CustomRoute> <FormCadastroFeedback /> </CustomRoute>} />
                <Route path="/feedback/formFeedback/:id" element={<CustomRoute> <FormFeedback /> </CustomRoute>} />
                <Route path="/feedback/assessment/:id" element={<CustomRoute> <FormAssessment /> </CustomRoute>} />
                <Route path="/pdi" element={<CustomRoute> <ListPDI /> </CustomRoute>} />
                <Route path="/pdi/cadastro" element={<CustomRoute> <FormPdi /> </CustomRoute>} />
                <Route path="/pdi/cadastro/:id" element={<CustomRoute> <FormPdi /> </CustomRoute>} />
                <Route path="/pdi/actionplan/:id" element={<CustomRoute> <ActionPlan /> </CustomRoute>} />
                <Route path="/pdi/activity/:pdi_id/actionplan/:id/call_action/:call_action" element={<CustomRoute> <FormInsertActivity /> </CustomRoute>} />
                {/* Rotas abaixo não devem ser protegidas por login */}
                <Route path="/formEnergymotivation" element={<FormEnergyMotivation />} />
                <Route path="/formEnergymotivation/:user_id/:campaign_id" element={<FormEnergyMotivation />} />
                <Route path="/formSafetyCheck" element={<FormSafetyCheck />} />
                <Route path="/formSafetyCheck/:user_id/:campaign_id" element={<FormSafetyCheck />} />
                <Route path="/formHappinessradar" element={<FormHappinessRadar />} />
                <Route path="/formHappinessradar/:user_id/:campaign_id" element={<FormHappinessRadar />} />
                <Route path="/redirect" element={<ThankYouPage />} />
                <Route path="/login" element={<LoginPage />} />
                <Route path="/login/cadastroLogin/:account_uuid" element={<FormCadLogin />} />
                <Route path="/login/cadastroAccount" element={<FormCadAccount />} />
                <Route path="/login/recovery" element={<Recovery />} />
                <Route path="/login/reset/:login_uuid" element={<Reset />} />
              </Routes>
            </main>
          </div>
        </ThemeProvider>
      </ColorModeContext.Provider>
    </AuthProvider>
  );
}

export default App;

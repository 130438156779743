import { Box, useTheme } from "@mui/material";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { tokens } from "../../theme";
import Header from "../../components/Header";
import { useState, useEffect } from 'react';
import FAB from "../../components/FAB";
import EditIcon from "@mui/icons-material/Edit";
import Delete from "@mui/icons-material/Delete";
import AddIcon from "@mui/icons-material/Add";
import TeamProfile from "../../hooks/useTeam";
import '../../utils/i18n';
import { useTranslation } from "react-i18next";

const User = () => {
  const { t, i18n } = useTranslation();
  const [user,setUser] = useState([]);
  const [list, setList] = useState([]);

  const api = process.env.REACT_APP_API_URL;

  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const columns = [
    {field: "id", headerName: "ID"},
    {
      field: "name",
      headerName: t("Name"),
      flex: 1,
      cellClassName: "name-column--cell",
    },
    {
      field: "email",
      headerName: "E-mail",
      flex: 1,
    },
    {
      field: "phone",
      headerName: t("Phone"),
      flex: 1,
    },
    {
      field: "whatsapp",
      headerName: "Whatsapp",
      flex: 1,
    },
    {
      field: "telegram",
      headerName: "Telegram",
      flex: 1,
    },
    {
      field: "position",
      headerName: t("Position"),
      flex: 1,
    },
    {
      field: "people_target",
      headerName: t("People Target"),
      flex: 1,
    },
    {
      field: "teamName",
      headerName: t("Team"),
      flex: 1,
    },
  ];
 
  const fetchData = async () =>{
    const url = `${api}/user/team/${TeamProfile.getTeamID()}`;
    const options = {
      method: 'GET',
      headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "http://localhost:3000",
          "Access-Control-Allow-Methods": "POST, GET, PUT, DELETE",
          "Access-Control-Allow-Headers": "*",
          "Access-Control-Max-Age": "86400",
          "Authorization": `${sessionStorage.getItem('token')}`
        }
    };
    const resp = await fetch(url, options);
    const json = await resp.json();
    setUser(json);
};
  
  useEffect(() => {
    fetchData();
  }, [TeamProfile.getTeamID()]);
  
  const handleDelete = async () => {
    if(list.length > 1){
      list.forEach(async id => {
        const url = `${api}/user/${id}`;
        const request = await fetch(url, {
          method: "DELETE",
          headers: {
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "http://localhost:3000",
            "Access-Control-Allow-Methods": "POST, GET, PUT, DELETE",
            "Access-Control-Allow-Headers": "*",
            "Access-Control-Max-Age": "86400",
            "Authorization": `${sessionStorage.getItem('token')}`
          },
        });

        fetchData(); //este fetchData serve para atualizar a grid após a deleção
      });
    }
    else{
      const url = `${api}/user/${list}`;
      const request = await fetch(url, {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "http://localhost:3000",
          "Access-Control-Allow-Methods": "POST, GET, PUT, DELETE",
          "Access-Control-Allow-Headers": "*",
          "Access-Control-Max-Age": "86400",
          "Authorization": `${sessionStorage.getItem('token')}`
        },
      });

      fetchData(); //este fetchData serve para atualizar a grid após a deleção
    }
  }
  
  return (
    <Box m="20px">
      <Header title={t("MANAGE TEAM USERS")} subtitle={t("Team users list")} />
      <Box
        m="40px 0 0 0"
        height="69vh"
        sx={{
          "& .MuiDataGrid-root": {
            border: "none",
          },
          "& .MuiDataGrid-cell": {
            borderBottom: "none",
          },
          "& .name-column--cell": {
            color: colors.greenAccent[300],
          },
          "& .MuiDataGrid-columnHeaders": {
            backgroundColor: colors.blueAccent[700],
            borderBottom: "none",
          },
          "& .MuiDataGrid-virtualScroller": {
            backgroundColor: colors.primary[400],
          },
          "& .MuiDataGrid-footerContainer": {
            borderTop: "none",
            backgroundColor: colors.blueAccent[700],
          },
          "& .MuiCheckbox-root": {
            color: `${colors.greenAccent[200]} !important`,
          },
          "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
            color: `${colors.grey[100]} !important`,
          },
        }}
      >
        <Box  display="flex" mr="10px" justifyContent="end" >
          <FAB
            to="/user/formUser"
            icon={<AddIcon />}
            title="Add new user"
          />
          &nbsp; &nbsp;
          <FAB
            to={`/user/formUser/${list[0]}`}
            icon={<EditIcon />}
            disabled={list.length === 1 ? false : true}
            title="Edit user"
          />
          &nbsp; &nbsp;
          <FAB
            to="/user"
            icon={<Delete />}
            disabled={list.length >= 1 ? false : true}
            id={list}
            handleDelete={handleDelete}
            name="delete"
            title="Delete user"
          />
        </Box>
        {/* 
        <ControlGrid
          list = {list}
        />
        */}
        <DataGrid 
          checkboxSelection
          rows={user ? user : false}
          columns={columns}
          components={{ Toolbar: GridToolbar }}
          onSelectionModelChange={setList}
      />
      </Box>
    </Box>
  );
};

export default User;

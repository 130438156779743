import { Box, Button, IconButton, Typography, useTheme } from "@mui/material";
import { tokens } from "../../theme";
import DownloadOutlinedIcon from "@mui/icons-material/DownloadOutlined";
import AutoAwesomeOutlinedIcon from '@mui/icons-material/AutoAwesomeOutlined';
import FavoriteBorderOutlinedIcon from '@mui/icons-material/FavoriteBorderOutlined';
import ElectricBoltOutlinedIcon from '@mui/icons-material/ElectricBoltOutlined';
import SafetyCheckOutlinedIcon from '@mui/icons-material/SafetyCheckOutlined';
import Header from "../../components/Header";
import LineChart from "../../components/LineChart";
import BarChart from "../../components/BarChart";
import StatBox from "../../components/StatBox";
import { useParams, useLocation } from "react-router-dom";
import { useEffect, useState } from "react";
import RadarType from "../../components/RadarChart";
import TeamProfile from "../../hooks/useTeam";
import '../../utils/i18n';
import { useTranslation } from 'react-i18next';

const mocklineChart = [
  {
    "id": 0,
    "data": [
        {
            "x": "",
            "y": 0
        },
    ]
  }
];

const mockKeyLineChart = ['People'];

const Satisfaction = () => {
  const {t, i18n} = useTranslation();
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [happinessRadar, setHappinessRadar] = useState([]);
  const [energiaMotivacao, setEnergiaMotivacao] = useState([]);
  const [safetyCheck, setSafetyCheck] = useState([]);
  const [lineChart, setLineChart] = useState([]);
  const [countCampaign, setCountCampaign] = useState([]);
  const [peopleTarget, setPeopleTarget] = useState([]);
  const [keys, setKeys] = useState([]);
  const [totalScore, setTotalScore] = useState();

  const location = useLocation();

  const api = process.env.REACT_APP_API_URL;
  
  //const {campaign_id} = useParams();

  const fetchHappinessRadarLineChart = async () => {
    const urlTimeLine = `${api}/dashboard/satisfactionlinechart/team/${TeamProfile.getTeamID()}`;
    const options = {
      method: 'GET',
      headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "http://localhost:3000",
          "Access-Control-Allow-Methods": "POST, GET, PUT, DELETE",
          "Access-Control-Allow-Headers": "*",
          "Access-Control-Max-Age": "86400",
          "Authorization": sessionStorage.getItem('token')
        }
    };
    const respTimeLine = await fetch(urlTimeLine, options);
    const jsonTimeLine = await respTimeLine.json(respTimeLine);
    setLineChart(jsonTimeLine)
  };

  useEffect(() => {
    const fetchData = async () => {
      const fetchWithHeaders = async (url) => {
        const options = {
          method: 'GET',
          headers: {
              "Content-Type": "application/json",
              "Access-Control-Allow-Origin": "http://localhost:3000",
              "Access-Control-Allow-Methods": "POST, GET, PUT, DELETE",
              "Access-Control-Allow-Headers": "*",
              "Access-Control-Max-Age": "86400",
              "Authorization": sessionStorage.getItem('token')
          }
        };
        const resp = await fetch(url, options);
        return resp.json();

      };
      try{
        const [bigNumbersResponse,energyMotivationResponse,peopleTargetResponse,peopleTargetListResponse, totalScoreResult,safetyCheckResponse] = await Promise.all([
          fetchWithHeaders(`${api}/happinessradar/avgbignumberhappinessradar/team/${TeamProfile.getTeamID()}`),
          fetchWithHeaders(`${api}/motivacaoenergia/avgbignumbersmotivacaoenergia/team/${TeamProfile.getTeamID()}`),
          fetchWithHeaders(`${api}/dashboard/peopleTarget/team/${TeamProfile.getTeamID()}`),
          fetchWithHeaders(`${api}/dashboard/AvgPeopleTargetCampaignList/team/${TeamProfile.getTeamID()}`),
          fetchWithHeaders(`${api}/dashboard/totalScore/team/${TeamProfile.getTeamID()}`),
          fetchWithHeaders(`${api}/safetycheck/avgbignumbersafetycheck/team/${TeamProfile.getTeamID()}`),
        ]);
        
        setHappinessRadar(bigNumbersResponse);
        setEnergiaMotivacao(energyMotivationResponse);
        setCountCampaign(peopleTargetListResponse);
        setPeopleTarget(peopleTargetResponse);
        handleKeys(peopleTargetResponse);
        setTotalScore(totalScoreResult[0]['score']);
        setSafetyCheck(safetyCheckResponse);
      }
      catch (error) {
        console.error("Error fetching data", error);
      }

    };

    fetchHappinessRadarLineChart();
    fetchData();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [TeamProfile.getTeamID(), location.pathname]);

  const handleKeys = (peopleTarget) => {
    // eslint-disable-next-line no-array-constructor
    const key = new Array();
    if(peopleTarget){
      peopleTarget.map((itens) =>{
        key.push(itens.people_target);
        return key;
      });
    }
    setKeys(key);
  };

  let satisfaction_score;
  let energy_score;
  let motivation_score;
  let safety_score;
  let total_score; 

  if(happinessRadar){
    happinessRadar.map((itens) => {
      satisfaction_score = itens.happinessRadar;
      total_score = 0;
      return total_score;
    });
  }

  if(safetyCheck){
    safetyCheck.map((item)=> {
      safety_score = item.score;
      return safety_score;
    });
  }

  if(energiaMotivacao){
    energiaMotivacao.map((itens) => {
      switch(t(itens.item)){
        case t("Energy"):
          energy_score = itens.score;
          break;
        case t("Motivation"):
          motivation_score = itens.score;
          break;
        default:
      }
      return null;
    });
  }


  const handleIncrease = (score) => {
    let result = (parseFloat(score) / 5) * 100 ;
    return result
  };

  const handleToFixedIncrease = (score) => {
    return score.toFixed(2);
  };

  const dataRadarChart = [
    {
      subject: t("Happiness Radar"),
      A: satisfaction_score,
      fullMark: 5
    },
    {
      subject: t("Energy"),
      A: energy_score,
      fullMark: 5
    },
    {
      subject: t("Motivation"),
      A: motivation_score,
      fullMark: 5
    },
    {
      subject: t("Safety"),
      A: safety_score,
      fullMark: 5
    },
  ];

  return (
    <Box m="20px">
      {/* HEADER */}
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Header title={t("SATISFACTION DASHBOARD")} subtitle={t("Welcome to satisfaction level dashboard")} />
        <Box>
          <Button
            sx={{
              backgroundColor: colors.blueAccent[700],
              color: colors.grey[100],
              fontSize: "14px",
              fontWeight: "bold",
              padding: "10px 20px",
            }}
          >
            <DownloadOutlinedIcon sx={{ mr: "10px" }} />
            Download Reports
          </Button>
        </Box>
      </Box>

      {/* GRID & CHARTS */}
      <Box
        display="grid"
        gridTemplateColumns="repeat(12, 1fr)"
        gridAutoRows="140px"
        gap="20px"
      >
        {/* ROW 1 */}
        <Box
          gridColumn="span 3"
          backgroundColor={colors.primary[400]}
          display="flex"
          alignItems="center"
          justifyContent="center"
          borderRadius="10px"
        >
          <StatBox
            title={satisfaction_score ? handleToFixedIncrease(satisfaction_score) : "Pesquisa não respondida para esse time"}
            subtitle={t("Happiness Radar")}
            progress={handleIncrease(satisfaction_score) / 100}
            increase={handleIncrease(satisfaction_score).toFixed(0) + "%"}
            icon={
              <FavoriteBorderOutlinedIcon
                sx={{ color: colors.greenAccent[600], fontSize: "26px" }}
              />
            }
          />
        </Box>
        <Box
          gridColumn="span 3"
          backgroundColor={colors.primary[400]}
          display="flex"
          alignItems="center"
          justifyContent="center"
          borderRadius="10px"
        >
          <StatBox
            title={energy_score ? handleToFixedIncrease(energy_score) : "Pesquisa não respondida para esse time"}
            subtitle={t("Energy")}
            progress={handleIncrease(energy_score) / 100}
            increase={handleIncrease(energy_score).toFixed(0) + "%"}
            icon={
              <ElectricBoltOutlinedIcon
                sx={{ color: colors.greenAccent[600], fontSize: "26px" }}
              />
            }
          />
        </Box>
        <Box
          gridColumn="span 3"
          backgroundColor={colors.primary[400]}
          display="flex"
          alignItems="center"
          justifyContent="center"
          borderRadius="10px"
        >
          <StatBox
            title={motivation_score ? handleToFixedIncrease(motivation_score) : "Pesquisa não respondida para esse time"}
            subtitle={t("Motivation")}
            progress={handleIncrease(motivation_score) / 100}
            increase={handleIncrease(motivation_score).toFixed(0) + "%"}
            icon={
              <AutoAwesomeOutlinedIcon
                sx={{ color: colors.greenAccent[600], fontSize: "26px" }}
              />
            }
          />
        </Box>
        <Box
          gridColumn="span 3"
          backgroundColor={colors.primary[400]}
          display="flex"
          alignItems="center"
          justifyContent="center"
          borderRadius="10px"
        >
          <StatBox
            title={safety_score ? handleToFixedIncrease(safety_score) : "Pesquisa não respondida para esse time"}
            subtitle={t("Safety")}
            progress={handleIncrease(safety_score) / 100}
            increase={handleIncrease(safety_score).toFixed(0) + "%"}
            icon={
              <SafetyCheckOutlinedIcon
                sx={{ color: colors.greenAccent[600], fontSize: "26px" }}
              />
            }
          />
        </Box>

        {/* ROW 2 */}
        <Box
          gridColumn="span 8"
          gridRow="span 2"
          backgroundColor={colors.primary[400]}
          borderRadius="10px"
        >
          <Box
            mt="25px"
            p="0 30px"
            display="flex "
            justifyContent="space-between"
            alignItems="center"
          >
            <Box>
              <Typography
                variant="h5"
                fontWeight="600"
                color={colors.grey[100]}
              >
                {t("Satisfaction Time Line")}
              </Typography>
              <Typography
                variant="h3"
                fontWeight="bold"
                color={colors.greenAccent[500]}
              >
                {totalScore ? totalScore : "Pesquisa não respondida para esse time"}
              </Typography>
            </Box>
            <Box>
              <IconButton>
                <DownloadOutlinedIcon
                  sx={{ fontSize: "26px", color: colors.greenAccent[500] }}
                />
              </IconButton>
            </Box>
          </Box>
          <Box height="250px" m="-20px 0 0 0">
            <LineChart isDashboard={false} data={lineChart ? lineChart : mocklineChart} keys={keys ? keys : mockKeyLineChart} maxValue={5}  />
          </Box>
        </Box>
        <Box
          gridColumn="span 4"
          gridRow="span 2"
          backgroundColor={colors.primary[400]}
          p="30px"
          borderRadius="10px"
        >
          <Typography variant="h5" fontWeight="600">
            {t("Satisfaction Radar")}
          </Typography>
          <RadarType data={dataRadarChart ? dataRadarChart : null} />
          <Box
            display="flex"
            flexDirection="column"
            alignItems="center"
            mt="25px"
          >
          </Box>
        </Box>

        {/* ROW 3 */}
        <Box
          gridColumn="span 8"
          gridRow="span 2"
          backgroundColor={colors.primary[400]}
          borderRadius="10px"
        >
          <Typography
            variant="h5"
            fontWeight="600"
            sx={{ padding: "30px 30px 0 30px" }}
          >
            {t("Satisfaction level by People")}
          </Typography>
          <Box height="250px" mt="-20px">
            {<BarChart
              isDashboard={true}
              data={peopleTarget ? peopleTarget : null}
              keys={keys ? keys : null}
              indexBy="people_target"
              groupMode="stack"
              interactive={false}
              maxValue={5}  />}
          </Box>
        </Box>
        <Box
          gridColumn="span 4"
          gridRow="span 2"
          backgroundColor={colors.primary[400]}
          overflow="auto"
          borderRadius="10px"
        >
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            borderBottom={`4px solid ${colors.primary[500]}`}
            colors={colors.grey[100]}
            p="15px"
          >
            <Typography color={colors.grey[100]} variant="h5" fontWeight="600">
              {t("Satisfaction by Touch")}
            </Typography>
            {/*
            <Box
                backgroundColor={colors.greenAccent[500]}
                p="5px 10px"
                borderRadius="4px"
              >
                {total && handleToFixedIncrease(total)}
              </Box>
            */}
          </Box>
          {countCampaign && countCampaign.map((item, id) => (
            <Box
              key={id}
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              borderBottom={`4px solid ${colors.primary[500]}`}
              p="15px"
            >
              <Box>
                <Typography
                  color={colors.greenAccent[500]}
                  variant="h5"
                  fontWeight="600"
                >
                  {item.campaign_name}
                </Typography>
                <Typography color={colors.grey[100]}>
                  {item.people_target}
                </Typography>
              </Box>
              <Box color={colors.grey[100]}>{item.initial_date}</Box>
              <Box
                backgroundColor={colors.greenAccent[500]}
                p="5px 10px"
                borderRadius="4px"
              >
                {item.avg && handleToFixedIncrease(item.avg)}
              </Box>
            </Box>
          ))}
        </Box>
      </Box>
    </Box>
  );
};

export default Satisfaction;

import { Box, IconButton, Typography, useTheme, Select, MenuItem, FormControl, InputLabel } from "@mui/material";
import { tokens } from "../../theme";
import DownloadOutlinedIcon from "@mui/icons-material/DownloadOutlined";
import Header from "../../components/Header";
import LineChart from "../../components/LineChart";
import BarChart from "../../components/BarChart";
import { useLocation } from "react-router-dom";
import { useEffect, useState } from "react";
import TeamProfile from "../../hooks/useTeam";
import MyResponsiveHeatMap from "../../components/HeatMap";
import { Formik } from "formik";
import useMediaQuery from "@mui/material/useMediaQuery";
import '../../utils/i18n';
import { useTranslation } from 'react-i18next';

const mockHeatMap = [
  {
    "id": null,
    "data": [
      {
        "x": null,
        "y": null
      },
    ]
  },
];


const HeatMapDashboard = () => {
  const { t, i18n } = useTranslation();
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [heatMap, setHeatMap] = useState([]);
  const [option, setOption] = useState(0);

  const api = process.env.REACT_APP_API_URL;

  const isNonMobile = useMediaQuery("(min-width:600px)");

  const location = useLocation();

  let resp = null;

  initialValues.country = 1;

  const fetchData = async () => {
    const urlHeatMap = [`${api}/happinessradar/heatmap/${TeamProfile.getTeamID()}`,`${api}/happinessradar/heatmapitem/${TeamProfile.getTeamID()}`];
    const options = {
      method: 'GET',
      headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "http://localhost:3000",
          "Access-Control-Allow-Methods": "POST, GET, PUT, DELETE",
          "Access-Control-Allow-Headers": "*",
          "Access-Control-Max-Age": "86400",
          "Authorization": `${sessionStorage.getItem('token')}`
      }
    };
    const resp = await fetch(urlHeatMap[option], options);
    const json = await resp.json();
    setHeatMap(json);
  };

  useEffect(() => {
    fetchData();
  }, [option]);

  //Handle de submit do form
  const handleFormSubmit = async (values) => {
    //seta url para cadastro (POST) e grava o retorno da API no Resp
    const url = `${api}/login/recovery`;

    const user = {
      email : values.email,
    };
    const request = await fetch(url,{
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "http://localhost:3000",
        "Access-Control-Allow-Methods": "POST, GET, PUT, DELETE",
        "Access-Control-Allow-Headers": "Content-Type, Authorization",
        "Access-Control-Max-Age": "86400",
        "Acceped": "*/*",
        "Authorization": `${sessionStorage.getItem('token')}`
      },
      body: JSON.stringify(user),
    });
    if (request.ok) {
      if(parseInt(request.status) === 200){
        resp = parseInt(request.status);
      }      
    } else {
      resp = parseInt(request.status);
      console.error("Erro na requisição:", request.status);
    }
  };

  const handleChange = (event) => {
    setOption(event.target.value);
  }


  return (
    <Box m="20px">
      {/* HEADER */}
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Header title={t("HEATMAP DASHBOARD")} subtitle={t("Welcome to heatmap dashboard")} />
        <Box>
        <FormControl>
          <InputLabel>{t("Options")}</InputLabel>
            <Select
              fullWidth
              variant="filled"
              label="Country"
              value={option}
              onChange={handleChange}
              name="country"
              sx={{ gridColumn: "span 2" }}
              >
                <MenuItem value={0}>{t("Heatmap by Touch")}</MenuItem>
                <MenuItem value={1}>{t("Heatmap by Happiness Radar")}</MenuItem>
            </Select>
        </FormControl>
        </Box>
      </Box>

      {/* GRID & CHARTS */}
      <Box
        display="grid"
        gridTemplateColumns="repeat(12, 1fr)"
        gridAutoRows="140px"
        gap="20px"
      >

        {/* ROW 1 */}
        <Box
          gridColumn="span 12"
          gridRow="span 4"
          backgroundColor={colors.primary[400]}
          borderRadius="10px"
        >
          <Box
            mt="25px"
            p="0 30px"
            display="flex "
            justifyContent="space-between"
            alignItems="center"
          >
            <Box>
              <Typography
                variant="h5"
                fontWeight="600"
                color={colors.grey[100]}
              >
                {t("Heatmap")}
              </Typography>
              <Typography
                variant="h3"
                fontWeight="bold"
                color={colors.greenAccent[500]}
              >
              </Typography>
            </Box>
            <Box>
              <IconButton>
                <DownloadOutlinedIcon
                  sx={{ fontSize: "26px", color: colors.greenAccent[500] }}
                />
              </IconButton>
            </Box>
          </Box>
          <Box height="90%" padding="20px">
            {/*<LineChart isDashboard={false} data={lineChart ? lineChart : mocklineChart} keys={keysLineChart ? keysLineChart : mockKeyLineChart} maxValue={5}  />*/}
            {heatMap ? <MyResponsiveHeatMap data={heatMap ? heatMap : mockHeatMap} /> : null}
          </Box>
        </Box>
      {/* ROW 1 */}
      </Box>
    </Box>
  );
};

const initialValues = {
  country: "",
};

export default HeatMapDashboard;
